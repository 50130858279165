import React, { useEffect } from "react";
import { Box } from "@mui/material";
import vubImg from "../assets/vub.png";
import mcImg from "../assets/mastercard-big.png";
import { useAppContext } from "../contexts/AppContext";
import confetti1 from "../assets/confetti1.png";
import confetti2 from "../assets/confetti2.png";
import confetti3 from "../assets/confetti3.png";
import confetti4 from "../assets/confetti4.png";
import confetti5 from "../assets/confetti5.png";
import confetti6 from "../assets/confetti6.png";
import confetti7 from "../assets/confetti7.png";
import confetti8 from "../assets/confetti8.png";
import confetti9 from "../assets/confetti9.png";
import confetti10 from "../assets/confetti10.png";
import confetti11 from "../assets/confetti11.png";
import confetti12 from "../assets/confetti12.png";
import confetti13 from "../assets/confetti13.png";
import confetti14 from "../assets/confetti14.png";
import confetti15 from "../assets/confetti15.png";
import confetti16 from "../assets/confetti16.png";
import Button from "../components/Button";

const End = () => {
  const { activeSlide } = useAppContext();

  const isActive = activeSlide === 31;

  useEffect(() => {
    if (!isActive) {
      return;
    }

    window.confetti({
      spread: 360,
      ticks: 200,
      gravity: 1,
      decay: 0.94,
      startVelocity: 40,
      particleCount: 150,
      scalar: 6,
      shapes: ["image"],
      shapeOptions: {
        image: [
          {
            src: confetti1,
            width: 137,
            height: 114,
          },
          {
            src: confetti2,
            width: 144,
            height: 139,
          },
          {
            src: confetti3,
            width: 139,
            height: 119,
          },
          {
            src: confetti4,
            width: 137,
            height: 144,
          },
          {
            src: confetti5,
            width: 133,
            height: 108,
          },
          {
            src: confetti6,
            width: 80,
            height: 81,
          },
          {
            src: confetti7,
            width: 65,
            height: 70,
          },
          {
            src: confetti8,
            width: 67,
            height: 65,
          },
          {
            src: confetti9,
            width: 87,
            height: 87,
          },
          {
            src: confetti10,
            width: 53,
            height: 59,
          },
          {
            src: confetti11,
            width: 99,
            height: 79,
          },
          {
            src: confetti12,
            width: 99,
            height: 79,
          },
          {
            src: confetti13,
            width: 147,
            height: 157,
          },
          {
            src: confetti14,
            width: 60,
            height: 51,
          },
          {
            src: confetti15,
            width: 51,
            height: 45,
          },
          {
            src: confetti16,
            width: 134,
            height: 120,
          },
        ],
      },
    });
  }, [isActive]);

  return (
    <Box
      sx={{
        minHeight: "100lvh",
        display: "flex",
        flexDirection: "column",
        padding: "12vh 2vw 3.7vh 3.3vw",
        "@media(max-width: 800px)": {
          padding: "50px 30px",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
          alignItems: "center",
          justifyContent: "center",
          "@media(max-width: 800px)": {
            flex: "1",
            marginBottom: "55px",
          },
        }}
      >
        <Box
          sx={{
            "@media(max-width: 800px)": {
              flex: "1",
            },
          }}
        >
          <Box
            className={"line-height-1-5"}
            sx={{
              marginBottom: "4vh",
              marginLeft: "10px",
              fontSize: "22px",
              fontWeight: "600",
              textAlign: "center",
              "@media(max-width: 800px)": {
                marginBottom: "38px",
                marginLeft: "7px",
                letterSpacing: "-0.6px",
                "& br": {
                  display: "none",
                },
              },
            }}
          >
            Skvelé, ďakujeme, že ste sa zapojili <br />
            do súťaže. O výsledkoch vás budeme <br />
            informovať prostredníctvom sms.
          </Box>

          <Button
            sx={{
              margin: "0 auto",
            }}
            onClick={() => window.location.reload()}
          >
            Ukončiť
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          "@media(max-width: 800px)": {
            justifyContent: "start",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginBottom: "12px",
              gap: "24px",
              "@media(max-width: 800px)": {
                justifyContent: "start",
              },
            }}
          >
            <Box
              component={"img"}
              src={vubImg}
              sx={{
                width: "135px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default End;
