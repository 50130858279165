import { v4 as uuidv4 } from "uuid";

import hackerOrange1Img from "../assets/hackerOrange1.png";
import hackerOrange2Img from "../assets/hackerOrange2.png";
import hackerOrange3Img from "../assets/hackerOrange3.png";
import hackerOrange4Img from "../assets/hackerOrange4.png";
import hackerOrange5Img from "../assets/hackerOrange5.png";

import hackerBlack1Img from "../assets/hackerBlack1.png";
import hackerBlack2Img from "../assets/hackerBlack2.png";
import hackerBlack3Img from "../assets/hackerBlack3.png";
import hackerBlack4Img from "../assets/hackerBlack4.png";
import hackerBlack5Img from "../assets/hackerBlack5.png";

import explanation1 from "../assets/update/explanation1.svg";
import explanation2 from "../assets/update/explanation2.svg";

import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";
import img6 from "../assets/img6.png";
import img7 from "../assets/img7.png";

import answerGroup11 from "../assets/update/group11.svg";
import answerGroup12 from "../assets/update/group12.svg";
import answerGroup13 from "../assets/update/group13.svg";
import answerGroup14 from "../assets/update/group14.svg";
import answerGroup15 from "../assets/update/group15.svg";

import answerGroup21 from "../assets/update/group21.svg";
import answerGroup22 from "../assets/update/group22.svg";
import answerGroup23 from "../assets/update/group23.svg";
import answerGroup23update from "../assets/update/group23update.png";
import answerGroup24 from "../assets/update/group24.svg";

import lottie1 from "../assets/update/lottie/lottie1.json";
import lottie2 from "../assets/update/lottie/lottie2.json";
import lottie3 from "../assets/update/lottie/lottie3.json";
import lottie4 from "../assets/update/lottie/lottie4.json";
import lottie5 from "../assets/update/lottie/lottie5.json";
import lottie6 from "../assets/update/lottie/lottie6.json";
import lottie7 from "../assets/update/lottie/lottie7.json";
import lottie8 from "../assets/update/lottie/lottie8.json";
import lottie9 from "../assets/update/lottie/lottie9.json";
import lottie10 from "../assets/update/lottie/lottie10.json";
import lottie11 from "../assets/update/lottie/lottie11.json";

import questionImg3 from "../assets/update/question3.svg";
import questionImg10 from "../assets/update/question10.svg";

import hackerLottie1 from "../assets/update/lottie/01.json";
import hackerLottie2 from "../assets/update/lottie/02.json";
import hackerLottie3 from "../assets/update/lottie/03.json";
import hackerLottie4 from "../assets/update/lottie/04.json";

import smsNotifLottie from "../assets/update/lottie/SMS_NOTIF_EXPORT.json";
import cloudsLottie from "../assets/update/lottie/EXPORT_01_CLOUDS.json";
import peniazeLottie from "../assets/update/lottie/EXPORT_PENIAZE.json";
import question2Img from "../assets/update/VUB_BP_2.png";
import question9_img2 from "../assets/update/question9_img2update.png";
import { Box } from "@mui/material";

const questions = [
  // 1
  {
    id: uuidv4(),
    question: (
      <>
        Čo by ste mali urobiť, ak dostanete <br />
        správu: „Náš systém zistil neoprávnené <br />
        prihlásenie do vášho bankového účtu. <br />
        Pre odblokovanie účtu kliknite na <br />
        nasledovný link:
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: <>Kliknem na link a&nbsp;budem postupovať podľa inštrukcií.</>,
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Najskôr sa prihlásim do svojho mobilného bankovníctva, overím, či sú
            všetky peniaze na účte. Potom otvorím link a&nbsp;potvrdím
            požadované údaje.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: "Kontaktujem svoju banku.",
      },
    ],
    correctAnswers: [2],
    lottie: cloudsLottie,
    loop: true,
    explanationImg: explanation1,
    explanationText: (
      <span>
        Banka vás prostredníctvom SMS alebo{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          e-mailu
        </span>{" "}
        nikdy nevyzýva na zadávanie citlivých bankových údajov na internete z
        dôvodu odblokovania účtu alebo aktualizácie údajov.
      </span>
    ),
  },

  // 2
  {
    id: uuidv4(),
    question: (
      <>
        Práve ste v práci a príde vám <br />
        upozornenie na povolenie platby. <br />
        Čo urobíte?
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: "Platbu potvrdím, je to určite manžel/manželka.",
      },
      {
        id: uuidv4(),
        answer:
          "Pozriem si svoje pravidelné platby, určite ide o predplatné TV, platbu potvrdím.",
      },
      {
        id: uuidv4(),
        answer: "Keďže práve nič nenakupujem, nemám dôvod nič potvrdzovať.",
      },
      {
        id: uuidv4(),
        answer: <>Kliknem na link a&nbsp;budem postupovať podľa inštrukcií.</>,
      },
    ],
    correctAnswers: [2],
    img: question2Img,
    loop: true,
    isFullSize: true,
    explanationOffset: true,
    explanationImg: explanation2,
    explanationText:
      "Nikdy nepotvrdzujte platbu, ak práve nič nenakupujete. Údaje o karte s nikým nezdieľajte, dokonca ani s príbuznými.",
  },

  // 3
  {
    id: uuidv4(),
    question: (
      <>
        Prišla vám správa, že ste sa stali <br />
        výhercom/výherkyňou súťaže. Na <br />
        prevzatie výhry stačí zadať vaše osobné <br />
        údaje, číslo karty, CVV kód a dátum <br />
        exspirácie. Čo urobíte?
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: "Výhra je lákavá, poskytnem údaje, veď nič neplatím.",
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Zadávať citlivé údaje kvôli výhre, keď som sa do žiadnej súťaže{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              nezapojil/-la,
            </span>{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              je podozrivé.
            </span>
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: "Príspevok má veľa lajkov, údaje poskytnem.",
      },
    ],
    correctAnswers: [1],
    img: questionImg3,
    explanationImg: explanation1,
    explanationText:
      "Osobné údaje a údaje o karte sú príliš citlivé. Nikdy ich nezdieľajte a neprezrádzajte cudzím osobám.",
  },

  // 4
  {
    id: uuidv4(),
    question: (
      <>
        Ktorý spôsob je najbezpečnejší na <br />
        prihlásenie sa do internet bankingu? <br />
        Označte správnu odpoveď.
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer:
          "Cez oficiálnu stránku alebo priamo cez adresu https://nib.vub.sk či https://ib.vub.sk.",
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Vyhľadať si adresu cez akýkoľvek webový prehliadač a&nbsp;kliknúť na
            prvý možný link.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Požiadať priateľa o&nbsp;prihlásenie a&nbsp;poskytnúť mu svoje
            údaje.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: "Pripájať sa len cez prehliadač Mozilla Firefox.",
      },
    ],
    correctAnswers: [0],
    lottie: lottie1,
    loop: true,
    explanationOffset: true,
    explanationImg: explanation2,
    explanationText:
      "Do internet bankingu sa neprihlasujte cez odkazy nájdené v internetovom prehliadači ani cez link, ktorý dostanete v správe a mailoch od neznámych osôb.",
  },

  // 5
  {
    id: uuidv4(),
    question: (
      <>
        Predávate tovar na bazári. <br />
        Cez WhatsApp vás kontaktuje kupujúci. <br />
        O tovar má vážny záujem. Navrhne, <br />
        že zabezpečí kuriéra, aby doručenie <br />
        prebehlo čo najrýchlejšie. Čo urobíte?
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: (
          <>
            Keďže mi kupujúci zaslal odkaz na platobnú bránu, zadám svoje údaje
            a&nbsp;čakám na peniaze, ktoré mi kupujúci prevedie.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Tovar pošlem po prijatí platby na svoj účet, ktorý som{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              uviedol/-la
            </span>{" "}
            kupujúcemu.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer:
          "Aby mi mohol kupujúci zaplatiť, poskytnem mu údaje o svojej karte.",
      },
    ],
    correctAnswers: [1],
    lottie: smsNotifLottie,
    loop: false,
    isFullSize: true,
    explanationImg: explanation1,
    explanationText:
      "Nikdy nezadávajte údaje o karte, pokiaľ si nič nekupujete. Karta slúži na platenie, nie na prijímanie peňazí.",
  },

  // 6
  {
    id: uuidv4(),
    question: (
      <>
        Aké sú znaky neseriózneho resp. <br />
        falošného e-shopu? Viac odpovedí je <br />
        správnych.
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: "Nedá sa prepnúť jazyk do slovenčiny.",
      },
      {
        id: uuidv4(),
        answer: "Online chat nie je dostupný.",
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Má podozrivo nízke ceny a&nbsp;nemá uvedené žiadne kontaktné údaje.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: "Výrazná zľava až 90 % je podmienená krátkym časovým úsekom.",
      },
    ],
    correctAnswers: [2, 3],
    lottie: peniazeLottie,
    loop: true,
    explanationOffset: true,
    explanationImg: explanation2,
    explanationText:
      "Pri online nákupoch je dôležitá obozretnosť. Pred samotným nákupom odporúčame overiť si recenzie, pozornosť venovať dodacím lehotám a v neposlednom rade si všimnúť kontaktné údaje na samotného obchodníka. Ak natrafíte na internetový obchod, ktorý má až podozrivo nízke ceny, doteraz ste o ňom vôbec nepočuli, nemá žiadne recenzie či profily na sociálnych sieťach, zvážte nákup inde.",
  },

  // 7
  {
    id: uuidv4(),
    question: (
      <>
        Ako si môžete zvýšiť bezpečnosť vašich <br />
        financií? Pozor, označte viacero <br />
        správnych možností.
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: "Využívam bezkontaktné transakcie.",
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Pracujem s&nbsp;limitmi na kartách. Ak platím na internete menej
            často, limit si znížim na nulu alebo nízku hodnotu. Pred nákupom si
            limit pohodlne cez aplikáciu zvýšim na požadovanú sumu.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            V prípade straty/odcudzenia karty využijem najrýchlejší spôsob
            a&nbsp;kartu zablokujem v&nbsp;mobilnej aplikácii banky.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            V prípade straty karty kontaktujem kartovú spoločnosť Mastercard
            a&nbsp;VISA a&nbsp;nahlásim stratu tam.
          </>
        ),
      },
    ],
    correctAnswers: [0, 1, 2],
    lottie: lottie5,
    loop: true,
    explanationImg: explanation1,
    explanationText:
      "Využívajte bezkontaktné transakcie, fyzickú kartu nahraďte kartou v mobile, pracujte s limitmi na karte, neprezrádzajte aktivačné údaje a dáta o kartách, používajte silné a jedinečné heslá, v prípade potreby zablokujte kartu cez mobilnú aplikáciu. Kombináciou nielen týchto opatrení zvyšujete ochranu svojich financií.",
  },

  // 8
  {
    id: uuidv4(),
    question: (
      <>
        Boli vám doručené nasledovné správy. <br />
        Označte všetky správy, ktoré obsahujú <br />
        podozrivé znaky.
      </>
    ),
    answersImages: [
      {
        answer: answerGroup11,
        index: 0,
      },
      {
        answer: answerGroup12,
        index: 1,
      },
      {
        answer: answerGroup13,
        index: 2,
      },
      {
        answer: answerGroup14,
        index: 3,
      },
      {
        answer: answerGroup15,
        index: 4,
      },
    ],
    correctAnswers: [1, 2, 4],
    explanationOffset: true,
    explanationImg: explanation2,
    explanationText: (
      <span>
        Buďte skeptickí k{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          e-mailom
        </span>{" "}
        alebo odkazom, ktoré vyzývajú na okamžitú akciu. Overte si, či{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          e-mail
        </span>{" "}
        prichádza z dôveryhodného zdroja, venujte pozornosť detailom ako je URL
        adresa odosielateľa. Buďte opatrní pri otváraní{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          e-mailov
        </span>{" "}
        od neznámych odosielateľov alebo s podozrivými odkazmi a prílohami.
        Nereagujte na správy, ktoré sú vytvorené tak, aby získali vaše osobné
        alebo finančné informácie pomocou psychologických trikov.
      </span>
    ),
  },

  // 9
  {
    id: uuidv4(),
    question: (
      <>
        Boli vám doručené nasledovné správy. <br />
        Označte všetky správy, ktoré obsahujú <br />
        podozrivé znaky.
      </>
    ),
    answersImages: [
      {
        answer: answerGroup21,
        index: 0,
      },
      {
        answer: question9_img2,
        index: 1,
      },
      {
        answer: answerGroup23,
        index: 2,
      },
      {
        answer: answerGroup24,
        index: 3,
      },
    ],
    correctAnswers: [0, 2],
    explanationImg: explanation1,
    explanationText: (
      <span>
        Facebook obsahuje v názve podozrivý znak. V adrese
        notification@faceboook.com je o jedno písmeno o naviac. Slovenská pošta
        je správne, neobsahuje žiadny podozrivý znak. Železnice – v adrese je
        prípona com. Slovenské „štátne“ inštitúcie nepoužívajú príponu com.
        Železnice majú všade príponu sk. Túto zámenu prípon veľmi často
        podvodníci používajú. Slovak parcel Service je správne, neobsahuje
        žiadny podozrivý znak.
      </span>
    ),
  },

  // 10
  {
    id: uuidv4(),
    question: (
      <>
        Zavolá vám niekto a predstaví sa ako pracovník <br />
        banky. Uvedie, že došlo k narušeniu bezpečnosti, <br />
        a na účely overenia požiada o heslo na prístup <br /> do Internet
        Bankingu. Čo urobíte?
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer:
          "Keďže volá pracovník banky, poskytnem mu všetky údaje do môjho internet bankingu.",
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Okamžite hovor zruším, keďže celé heslo k Internet Bankingu nikomu
            neposkytujem a&nbsp;nahlásim podozrivé správanie cez Kontakt (call
            centrum banky).
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Opýtam sa ho, z&nbsp;akej pobočky volá a&nbsp;keď mi odpovie, tak mu
            poskytnem údaje.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Poviem mu len svoje ID, keďže len to by malo stačiť na moje
            overenie.
          </>
        ),
      },
    ],
    correctAnswers: [1],
    img: questionImg10,
    explanationOffset: true,
    explanationImg: explanation2,
    explanationText:
      "Pracovník banky nikdy nežiada od klienta heslo na prístup do Internet Bankingu, ani keby mohlo dôjsť k narušeniu bezpečnosti.",
  },

  // 11
  {
    id: uuidv4(),
    question: (
      <>
        Prihlasujete sa do vášho internet bankingu <br />
        cez internetový vyhľadávač, napr. Google. <br />
        Príde vám autorizačná SMS z banky. <br />
        Čo urobíte? Označte všetky správne <br /> možnosti.
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: (
          <>
            Dôsledne si prečítam, o&nbsp;akú autorizáciu ide, či naozaj ide
            o&nbsp;potvrdenie môjho prihlásenia.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Pri prihlasovaní cez vyhľadávač sa klient môže ľahko dostať na
            falošné stránky internetového bankovníctva. Následne môžu podvodníci
            využiť k jeho nepozornosť a&nbsp;získať tak prístup do jeho internet
            bankingu, zadať platbu alebo aktivovať MB. Bezpečnostný kód vždy
            vpisujem (vybrané znaky) len do formulára na url adrese
            https://idp.vub.sk/esa/login.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Automaticky prepíšem kód bez toho, aby som si{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              skontroloval/-la
            </span>{" "}
            na akej stránke sa v&nbsp;skutočnosti nachádzam.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Automaticky prepíšem kód na stránke bez toho, aby som si tú SMS
            poriadne{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              prečítal/-la
            </span>{" "}
            , veď je z banky.
          </>
        ),
      },
    ],
    correctAnswers: [0, 1],
    lottie: lottie7,
    loop: true,
    explanationImg: explanation1,
    explanationText:
      "Keď sa nachádzate na stránke internet bankingu, skontrolujte, či url stránky nie je podozrivo dlhé alebo či sa tam nenachádzajú špeciálne znaky. Podvodníci vedia vytvárať stránky, ktoré sa graficky veľmi podobajú originálu, ale url nevedia ukradnúť. Vždy, keď vám príde autorizácia SMS / PUSH správa, skontrolujte, čo autorizuje – táto informácia sa tam vždy nachádza.",
  },

  // 12
  {
    id: uuidv4(),
    question: (
      <>
        Príde vám SMS s linkom na aktiváciu <br />
        mobilnej aplikácie{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          VUB banking.
        </span>{" "}
        <br />
        Čo urobíte? Označte všetky správne <br />
        možnosti.
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: (
          <>
            Ak si práve neaktivujem mobilnú aplikáciu, neklikám na žiaden link
            a&nbsp;spojím sa s&nbsp;bankou cez Kontakt
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Práve si aktivujem mobilnú aplikáciu, a&nbsp;preto kontrolujem, či
            mi prišli 3 SMS-ky za sebou. V&nbsp;poslednej SMS-ke je aktivačný
            link aj s&nbsp;dátumom platnosti. V&nbsp;linku sa nenachádzajú
            žiadne polia na vyplnenie.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Práve si aktivujem mobilnú aplikáciu, preto rovno klikám na link
            a&nbsp;vyplňujem všetky údaje.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Ak si práve neaktivujem mobilnú aplikáciu, pre istotu kliknem aj na
            link a&nbsp;potvrdím všetky údaje.
          </>
        ),
      },
    ],
    correctAnswers: [0, 1],
    lottie: lottie3,
    loop: true,
    explanationOffset: true,
    explanationImg: explanation2,
    explanationText:
      "Keď si aktivujete mobilnú aplikáciu, očakávajte, že vám na vaše bezpečnostné číslo prídu 3 SMS správy a v tej poslednej bude aktivačný link. Keď na link kliknete, banka od vás určite nebude žiadať vypísať niekde osobné údaje (ID ani heslo). Ak si práve neaktualizujete mobilnú aplikáciu, neklikajte na žiaden aktivačný link.",
  },

  // 13
  {
    id: uuidv4(),
    question: (
      <>
        Platíte u obchodníka vašou platobnou kartou <br />
        a je potrebné overiť platbu cez 3-D Secure. <br />
        Zvolíte možnosť cez mobilnú aplikáciu. Príde <br />
        vám PUSH správa do aplikácie{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          VUB banking.
        </span>{" "}
        <br />
        Čo je potrebné skontrolovať pred potvrdením?
      </>
    ),
    answers: [
      {
        id: uuidv4(),
        answer: (
          <>
            Keďže mi prišla PUSH správa na moju mobilnú aplikáciu, nekontrolujem
            nič a&nbsp;rovno potvrdzujem s&nbsp;PIN-om alebo biometriou.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Prihlásim sa do aplikácie, prejdem do sekcie Karty
            a&nbsp;skontrolujem, či som{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              zadal/-la
            </span>{" "}
            správne číslo karty.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: (
          <>
            Po kliku na PUSH správu skontrolujem meno obchodníka, sumu
            a&nbsp;menu platby, či sedí s tým, čo som{" "}
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              zadával/-la
            </span>{" "}
            u obchodníka. Až keď všetko sedí, následne potvrdím PIN-om alebo
            biometriou.
          </>
        ),
      },
      {
        id: uuidv4(),
        answer: <>Neklikám na žiadnu PUSH správu z&nbsp;dôvodu bezpečnosti.</>,
      },
    ],
    correctAnswers: [2],
    lottie: lottie4,
    loop: true,
    explanationImg: explanation1,
    explanationText:
      "Vždy, keď vám príde autorizačná PUSH správa, skontrolujte, čo autorizujete – táto informácia sa tam vždy nachádza. V prípade overenia platby cez 3-D Secure sa tam nachádzajú informácie: meno obchodníka, suma a mena platby. Potvrdzujte len to, čo sami inicializujete a čo viete skontrolovať. Ak vám príde autorizačná správa o aktivite, ktorú ste vy nezačínali (ako potvrdenie platby alebo aktivácia mobilnej aplikácie), nikdy ju nepotvrdzujte.",
  },
];

const letters = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
};

const annualCash = 8000;

const cashPerAnswer = 600;

const getHackerEmotion = (cash, variant) => {
  if (cash === 8000) {
    return {
      orange: hackerOrange1Img,
      black: hackerBlack1Img,
    }[variant];
  }

  if (cash >= 7000) {
    return {
      orange: hackerOrange2Img,
      black: hackerBlack2Img,
    }[variant];
  }

  if (cash >= 6000) {
    return {
      orange: hackerOrange3Img,
      black: hackerBlack3Img,
    }[variant];
  }

  if (cash >= 5000) {
    return {
      orange: hackerOrange4Img,
      black: hackerBlack4Img,
    }[variant];
  }

  return {
    orange: hackerOrange5Img,
    black: hackerBlack5Img,
  }[variant];
};

const getHackerEmotionLottie = (cash, variant) => {
  if (cash === 8000) {
    return {
      orange: hackerLottie3,
      black: hackerLottie3,
    }[variant];
  }

  if (cash >= 7000) {
    return {
      orange: hackerLottie2,
      black: hackerLottie2,
    }[variant];
  }

  if (cash >= 6000) {
    return {
      orange: hackerLottie4,
      black: hackerLottie4,
    }[variant];
  }

  if (cash >= 5000) {
    return {
      orange: hackerLottie1,
      black: hackerLottie1,
    }[variant];
  }

  return {
    orange: hackerLottie1,
    black: hackerLottie1,
  }[variant];
};

export {
  questions,
  letters,
  annualCash,
  cashPerAnswer,
  getHackerEmotion,
  getHackerEmotionLottie,
};
